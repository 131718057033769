import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { destroyCookie, getCookies, setCookie } from '@/services/cookies';
import auth from '@/api/auth';
import cart from '@/api/cart';
import { loadUserInfo } from '@/redux/checkout/checkoutReducer';
import { loadCartInfo } from '@/redux/cart/cartReducer';

const useAutoLogin = () => {
  const store = useSelector((state) => state.store);
  const checkout = useSelector((state) => state.checkout);
  const dispatch = useDispatch();
  const router = useRouter();

  const ejec = async () => {
    const abandonedCartToken = router.query.cartToken;
    if (abandonedCartToken) {
      setCookie('cartToken', abandonedCartToken);
    }
    const { token, cartToken } = getCookies();
    let user;
    if (typeof window !== 'undefined') {
      user = window.localStorage.getItem('user');
      if (!token && user) {
        window.localStorage.removeItem('user');
        window.localStorage.removeItem('marketingAutomationID');
      }
    }

    if (token && !checkout.logged) {
      if (!user) {
        destroyCookie('token');
        destroyCookie('spsToken');
        destroyCookie('refreshToken');
      } else {
        const response = await auth.autoLogin();
        if (response.success) {
          dispatch(loadUserInfo(response.data));
        }
      }
    }
    if (cartToken) {
      const response = await cart.get(store);
      if (response.success) {
        dispatch(loadCartInfo(response.data));
      }
    }
  };
  return ejec;
};

export default useAutoLogin;
